import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    vueApp,
    $config: {
      public: { SENTRY_DSN, NUXT_PUBLIC_IS_PRODUCTION },
    },
  } = nuxtApp
  const router = useRouter()

  Sentry.init({
    app: [vueApp],
    dsn: SENTRY_DSN,
    environment: NUXT_PUBLIC_IS_PRODUCTION === '1' ? 'production' : 'development',
    enabled: true,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    beforeSend(event, hint) {
      // Check if the exception is a FetchError
      if (hint.originalException instanceof Error && hint.originalException.name === 'FetchError') {
        console.warn('FetchError detected, event will not be sent to Sentry.', { event, hint })
        return null
      }


      if (event.exception) {
        console.error(
            `[Exception handled by Sentry]: (${hint.originalException})`,
            { event, hint },
        )
      }

      return event
    },
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  )
  
  Sentry.attachErrorHandler(vueApp, {
    attachErrorHandler: true,
    logErrors: import.meta.env.MODE === 'development',
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
