import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as blogR6EtYuEVDlMeta } from "/app/pages/blog.vue?macro=true";
import { default as amm6w3r8wucnaMeta } from "/app/pages/events/amm.vue?macro=true";
import { default as apcmedU2oP0jv54uMeta } from "/app/pages/events/apcmed.vue?macro=true";
import { default as auditgeo9Ggjbun8EGMeta } from "/app/pages/events/auditgeo.vue?macro=true";
import { default as clubshmbXgl79uW26QMeta } from "/app/pages/events/clubshmb.vue?macro=true";
import { default as courseJQI4zfCoejMeta } from "/app/pages/events/course.vue?macro=true";
import { default as efirBF7ysxKhbxMeta } from "/app/pages/events/efir.vue?macro=true";
import { default as efir07wYrL0oqjQaMeta } from "/app/pages/events/efir07.vue?macro=true";
import { default as enjoyU30vfzr1jeMeta } from "/app/pages/events/enjoy.vue?macro=true";
import { default as eqivaDUok1KZahTMeta } from "/app/pages/events/eqiva.vue?macro=true";
import { default as gm24guRLEk1yD2Meta } from "/app/pages/events/gm24.vue?macro=true";
import { default as growthmed24wO60GGBR3MMeta } from "/app/pages/events/growthmed24.vue?macro=true";
import { default as mbsIa7g6ayZm4Meta } from "/app/pages/events/mbs.vue?macro=true";
import { default as med_marketing2024YM1dW2bgJ1Meta } from "/app/pages/events/med_marketing2024.vue?macro=true";
import { default as start1GSx48Kiv7Meta } from "/app/pages/events/start.vue?macro=true";
import { default as vademecum_liveF3iVFfLTDIMeta } from "/app/pages/events/vademecum_live.vue?macro=true";
import { default as vademecum1WepjSa0izMeta } from "/app/pages/events/vademecum.vue?macro=true";
import { default as vademecum2023drNaCGLkFEMeta } from "/app/pages/events/vademecum2023.vue?macro=true";
import { default as youtubez9onIN6LOBMeta } from "/app/pages/events/youtube.vue?macro=true";
import { default as youtubea8VtSJcpbBhMeta } from "/app/pages/events/youtubea.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as freeu10FS4kV7tMeta } from "/app/pages/free.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as license1oPxwXaJfhMeta } from "/app/pages/license.vue?macro=true";
import { default as medicineMGDCgROhWeMeta } from "/app/pages/medicine.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as _91_91seoCompanyType_93_934AAD1H9yKqMeta } from "/app/pages/rating/[[city]]/[[companyType]]/[[seoCity]]/[[seoCompanyType]].vue?macro=true";
import { default as _91_46_46_46slug_93_46clientWtv8dowT3UMeta } from "/app/pages/rating/embed/[...slug].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91_46_46_46slug_93kSUcUVTl5YMeta } from "/app/pages/rating/nearby/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93hOgT9Es2MRMeta } from "/app/pages/rating/org/[...slug].vue?macro=true";
import { default as reviewsmeL0YIzg7xMeta } from "/app/pages/reviews.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog.vue")
  },
  {
    name: "events-amm",
    path: "/events/amm",
    component: () => import("/app/pages/events/amm.vue")
  },
  {
    name: "events-apcmed",
    path: "/events/apcmed",
    component: () => import("/app/pages/events/apcmed.vue")
  },
  {
    name: "events-auditgeo",
    path: "/events/auditgeo",
    component: () => import("/app/pages/events/auditgeo.vue")
  },
  {
    name: "events-clubshmb",
    path: "/events/clubshmb",
    component: () => import("/app/pages/events/clubshmb.vue")
  },
  {
    name: "events-course",
    path: "/events/course",
    component: () => import("/app/pages/events/course.vue")
  },
  {
    name: "events-efir",
    path: "/events/efir",
    component: () => import("/app/pages/events/efir.vue")
  },
  {
    name: "events-efir07",
    path: "/events/efir07",
    component: () => import("/app/pages/events/efir07.vue")
  },
  {
    name: "events-enjoy",
    path: "/events/enjoy",
    component: () => import("/app/pages/events/enjoy.vue")
  },
  {
    name: "events-eqiva",
    path: "/events/eqiva",
    component: () => import("/app/pages/events/eqiva.vue")
  },
  {
    name: "events-gm24",
    path: "/events/gm24",
    component: () => import("/app/pages/events/gm24.vue")
  },
  {
    name: "events-growthmed24",
    path: "/events/growthmed24",
    component: () => import("/app/pages/events/growthmed24.vue")
  },
  {
    name: "events-mbs",
    path: "/events/mbs",
    component: () => import("/app/pages/events/mbs.vue")
  },
  {
    name: "events-med_marketing2024",
    path: "/events/med_marketing2024",
    component: () => import("/app/pages/events/med_marketing2024.vue")
  },
  {
    name: "events-start",
    path: "/events/start",
    component: () => import("/app/pages/events/start.vue")
  },
  {
    name: "events-vademecum_live",
    path: "/events/vademecum_live",
    component: () => import("/app/pages/events/vademecum_live.vue")
  },
  {
    name: "events-vademecum",
    path: "/events/vademecum",
    component: () => import("/app/pages/events/vademecum.vue")
  },
  {
    name: "events-vademecum2023",
    path: "/events/vademecum2023",
    component: () => import("/app/pages/events/vademecum2023.vue")
  },
  {
    name: "events-youtube",
    path: "/events/youtube",
    component: () => import("/app/pages/events/youtube.vue")
  },
  {
    name: "events-youtubea",
    path: "/events/youtubea",
    component: () => import("/app/pages/events/youtubea.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "free",
    path: "/free",
    component: () => import("/app/pages/free.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/app/pages/license.vue")
  },
  {
    name: "medicine",
    path: "/medicine",
    component: () => import("/app/pages/medicine.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "rating-city-companyType-seoCity-seoCompanyType",
    path: "/rating/:city?/:companyType?/:seoCity?/:seoCompanyType?",
    meta: _91_91seoCompanyType_93_934AAD1H9yKqMeta || {},
    component: () => import("/app/pages/rating/[[city]]/[[companyType]]/[[seoCity]]/[[seoCompanyType]].vue")
  },
  {
    name: "rating-embed-slug",
    path: "/rating/embed/:slug(.*)*",
    meta: _91_46_46_46slug_93_46clientWtv8dowT3UMeta || {},
    component: () => createClientPage(() => import("/app/pages/rating/embed/[...slug].client.vue"))
  },
  {
    name: "rating-nearby-slug",
    path: "/rating/nearby/:slug(.*)*",
    component: () => import("/app/pages/rating/nearby/[...slug].vue")
  },
  {
    name: "rating-org-slug",
    path: "/rating/org/:slug(.*)*",
    component: () => import("/app/pages/rating/org/[...slug].vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/app/pages/reviews.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/when-to-complain",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/yandex-filter",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/convenient-maps",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/how-to-respond",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/purchase-ratings",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/delete-review",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/return-patient",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/gm24",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/hotels",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/stomatologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/tsentry-diagnostiki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kosmetologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/oftalmologicheskie-tsentry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/detskie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/veterinarnie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/spb/stomatologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/spb/tsentry-diagnostiki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/spb/kosmetologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/spb/oftalmologicheskie-tsentry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/spb/detskie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/spb/veterinarnie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/novosibirsk/stomatologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/novosibirsk/tsentry-diagnostiki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/novosibirsk/kosmetologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/novosibirsk/oftalmologicheskie-tsentry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/novosibirsk/detskie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/novosibirsk/veterinarnie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/ekaterinburg/stomatologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/ekaterinburg/tsentry-diagnostiki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/ekaterinburg/kosmetologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/ekaterinburg/oftalmologicheskie-tsentry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/ekaterinburg/detskie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/ekaterinburg/veterinarnie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kazan/stomatologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kazan/tsentry-diagnostiki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kazan/kosmetologii",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kazan/oftalmologicheskie-tsentry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kazan/detskie-kliniki",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/rating/kazan/veterinarnie-kliniki",
    component: component_45stubnO9MU04yTU
  }
]